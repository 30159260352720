import { useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { axdTokenIsExpired, redirectToAuth } from '../../utils/auth';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../lib/redux/auth/slice';

export default function usePlatformRequest() {
  const dispatch = useDispatch();
  const pathParams = useParams();
  const ready = useSelector((state) => state.auth.ready);
  const mentor = useSelector((state) => state.mentors.mentor);

  const location = useLocation();

  const handlePlatformRequest = () => {
    const redirectPath = `${location.pathname}${location.search}`;
    const redirectUrl = `${window.location.origin}`;

    const anonymous = mentor?.settings?.allow_anonymous;
    const currentTenant = localStorage.getItem('tenant');

    if (
      !anonymous &&
      (pathParams.platform !== currentTenant || axdTokenIsExpired())
    ) {
      localStorage.setItem('redirect-path', redirectPath);
      return redirectToAuth(true, redirectUrl, pathParams.platform);
    } else {
      //set tenant localstorage data when embedded
      if (anonymous) {
        localStorage.setItem('tenant', pathParams.platform);
        try {
          const currentTenant = JSON.parse(
            localStorage.getItem('current_tenant')
          );
          currentTenant.key = pathParams.platform;
          localStorage.setItem('current_tenant', JSON.stringify(currentTenant));
        } catch (e) {}
      }
      dispatch(authActions.readyUpdated(true));
      return [];
    }
  };

  useEffect(() => {
    if (mentor) {
      handlePlatformRequest();
    }
  }, [mentor]);

  return { ready };
}
