import { useSelector } from 'react-redux';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import useApi from '../api/base/useApi';
import useMentorDataset from '../api/mentors/useMentorDataset';
import { useTriggers } from '../navigation';
import {
  DOCUMENTS_EXTENSIONS,
  getDatasetFormatExtensions,
  isValidUrl,
  UPLOAD_MAX_SIZE,
} from '../../utils/shared';
import { Error } from '../../modules/alerts';

export default function useDatasetResourceAdd() {
  const { dataset, edit } = useSelector((state) => state.mentors);
  const auth = useSelector((state) => state.auth.data);
  const path = `/api/ai-index/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/documents/train/`;

  const [file, setFile] = useState(null);
  const [url, setUrl] = useState('');
  const [branch, setBranch] = useState('');
  const [submitting, setSubmitting] = useState('');
  const api = useApi();
  const { _handleUpdateModalStatus, _handleUpdateMultipleModalStatus } =
    useTriggers();

  const showSuccessMessage = () => {
    toast.success('Resource has been submitted for training');
  };

  const handleSubmitFile = async ({ type = 'file' } = {}) => {
    const form = document.getElementById('dataset-document-form');
    const formData = new FormData(form);
    formData.append('pathway', edit?.mentor?.unique_id);
    formData.append('type', type);

    setSubmitting(true);
    const response = await api.post(path, formData, true);
    if (response?.data) {
      showSuccessMessage();
    } else {
      toast.custom(Error(response?.error));
    }
    setSubmitting(false);
  };

  const handleSubmitGithub = async () => {
    const data = {
      type: dataset?.type?.toLowerCase(),
      pathway: edit?.mentor?.unique_id,
      url,
      ...(dataset?.type === 'GitHub' && branch && { branch }),
    };
    setSubmitting(true);
    const response = await api.post(path, data);
    if (response?.data) {
      setUrl('');
      showSuccessMessage();
    } else {
      toast.custom(Error(response?.error));
    }
    setSubmitting(false);
  };

  const handleSubmitUrl = async () => {
    const data = {
      type: dataset?.type?.toLowerCase(),
      pathway: edit?.mentor?.unique_id,
      url,
    };
    setSubmitting(true);
    const response = await api.post(path, data);
    if (response?.data) {
      setUrl('');
      showSuccessMessage();
    } else {
      toast.custom(Error(response?.error));
    }
    setSubmitting(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    switch (dataset?.type) {
      case 'Course':
        await handleSubmitFile({ type: 'zip' });
        break;
      case 'GitHub':
        await handleSubmitGithub();
        break;
      case 'URL':
      case 'YouTube':
        await handleSubmitUrl();
        break;
      case 'Google Drive':
      case 'Dropbox':
        break;
      case 'Audio':
        await handleSubmitFile({ type: 'audio' });
        break;
      case 'Video':
        await handleSubmitFile({ type: 'video' });
        break;
      case 'Image':
        await handleSubmitFile({ type: 'image' });
        break;
      case 'ZIP Files':
        await handleSubmitFile({ type: 'zip' });
        break;
      default:
        await handleSubmitFile();
        break;
    }
    _handleUpdateMultipleModalStatus([
      { name: 'dataset-url-resource-modal', status: false },
      { name: 'add-resource-modal', status: false },
    ]);
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      if (
        !getDatasetFormatExtensions?.[dataset?.type]?.includes(
          '.' + files[0].name.split('.').pop()
        )
      ) {
        toast.custom(Error("The uploaded file extension isn't accepted!"));
        document.getElementById('dataset-file-input').value = '';
        return;
      }
      if (files[0].size > UPLOAD_MAX_SIZE) {
        toast.custom(
          Error(
            `The uploaded file shouldn't exceed ${UPLOAD_MAX_SIZE / (1024 * 1024)} MB.`
          )
        );
        document.getElementById('dataset-file-input').value = '';
        return;
      }
      setFile(files[0]);
    }
  };

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };

  const handleBranchChange = (event) => {
    setBranch(event.target.value);
  };

  return {
    dataset,
    handleFileChange,
    handleUrlChange,
    handleBranchChange,
    handleSubmit,
    submitting,
    url,
  };
}
