import PageLoader from '../loader/page-loader';
import { IndexView } from '../../views';
import usePlatformRequest from '../../hooks/platform/usePlatformRequest';
import { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { mentorActions } from '../../lib/redux/mentors/slice';

export default function Platform() {
  const { ready } = usePlatformRequest();
  const dispatch = useDispatch();
  const { mentor } = useSelector((state) => state.mentors);
  useEffect(() => {
    if (ready) {
      dispatch(mentorActions.editMentorUpdated(mentor));
    }
  }, [ready]);

  return (
    <>
      {ready ? (
        <>
          <IndexView />
          <Outlet />
        </>
      ) : (
        <PageLoader />
      )}
    </>
  );
}
