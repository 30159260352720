import { useDispatch, useSelector } from 'react-redux';
import { mentorActions } from '../../lib/redux/mentors/slice';
import { useTriggers } from '../navigation';
import useGoogleDrivePicker from '../api/mentors/useGoogleDrivePicker';
import useDropboxPicker from './useDropboxPicker';
import useOneDrivePicker from './useOneDrivePicker';
import useDocumentTrain from '../api/mentors/useDocumentTrain';
import { toast } from 'react-hot-toast';

const google = window.google;

export default function useDatasetTypes() {
  const dispatch = useDispatch();
  const dataset = useSelector((state) => state.mentors.dataset);
  const mentor = useSelector((state) => state.mentors.edit);
  const { _handleUpdateModalStatus } = useTriggers();
  const { handleDocumentTrain } = useDocumentTrain();
  const { openChooser, appKey } = useDropboxPicker({
    autoShow: false,
  });
  const { handlePickerOpen, credentials } = useGoogleDrivePicker();
  const { pickOneDriveFile, onedriveAppId } = useOneDrivePicker();

  const datasetTypes = [
    {
      type: 'PowerPoint',
      img_url: '/images/pptx.jpg',
      active: true,
    },
    {
      type: 'Microsoft OneDrive',
      img_url: '/images/OneDrive.png',
      active: true,
      ...(onedriveAppId ? {} : { tooltip: 'Credentials Not Loaded' }),
    },
    {
      type: 'Google Drive',
      img_url: '/images/google-color-svgrepo-com.svg',
      active: true, //!!credentials.developer_key,
      ...(credentials.developer_key
        ? {}
        : { tooltip: 'Credentials Not Loaded' }),
    },
    {
      type: 'Dropbox',
      img_url: '/images/dropbox.png',
      active: true, //!!appKey,
      ...(appKey ? {} : { tooltip: 'Credentials Not Loaded' }),
    },
    {
      type: 'YouTube',
      img_url: '/images/1384060.png',
      active: true,
    },
    {
      type: 'URL',
      img_url: '/images/7471685.png',
      active: true,
    },
    {
      type: 'PDF',
      img_url: '/images/pdf_icon_4726010.png',
      active: true,
    },
    {
      type: 'DOCX',
      img_url: '/images/Microsoft_Office_Word_Logo_512px.png',
      active: true,
    },
    {
      type: 'GitHub',
      img_url: '/images/github.png',
      active: true,
    },
    {
      type: 'TEXT',
      img_url: '/images/text_9546564.png',
      active: true,
    },
    {
      type: 'Audio',
      img_url:
        '/images/676f08adbb6db2151ea2728d_audio-play7189.logowik.com-removebg-preview.png',
      active: true,
    },
    {
      type: 'Video',
      img_url: '/images/65048a233ee9c46785a13ca6_video-camera.png',
      active: true,
    },
    {
      type: 'Image',
      img_url: '/images/image_4942906.png',
      active: true,
    },
    {
      type: 'ZIP Files',
      img_url: '/images/zip-icon.webp',
      active: true,
    },
    {
      type: 'Course',
      img_url:
        '/images/65048a233ee9c46785a13caa_book-copy_165048a233ee9c46785a13caa_book-copy.png',
      active: false,
    },
  ];

  const handleSuccess = async (files) => {
    const trainPayload = {
      pathway: mentor?.mentor?.unique_id,
      url: files.map((file) => file.link).join(','),
      type: 'dropbox',
      access: 'private',
      dropbox_auth_data: files,
    };
    handleDocumentTrain(trainPayload).then((data) =>
      toast.success('Document has been queued for training!')
    );
  };
  const handleSuccessOnedrive = (files) => {
    const trainPayload = {
      pathway: mentor?.mentor?.unique_id,
      url: files.value.map((e) => e['@microsoft.graph.downloadUrl']).join(','),
      type: 'onedrive',
      access: 'private',
    };
    handleDocumentTrain(trainPayload).then((data) =>
      toast.success('Document has been queued for training!')
    );
  };
  const handleSelect = (type) => {
    dispatch(mentorActions.datasetUpdated({ ...dataset, type: type }));
    if (type === 'Google Drive') {
      handlePickerOpen();
      return;
    }
    if (type === 'Dropbox') {
      openChooser(handleSuccess);
      return;
    }
    if (type === 'Microsoft OneDrive') {
      pickOneDriveFile(handleSuccessOnedrive, onedriveAppId);
      return;
    }
    _handleUpdateModalStatus('dataset-url-resource-modal', true);
  };

  const getInActiveStatus = (item) => {
    if (!item.active) {
      return 'inactive';
    }
    return '';
  };

  return { datasetTypes, getInActiveStatus, handleSelect };
}
