import "./Llmselectionmodalbox.css"
import { useTranslation } from 'react-i18next';
import { useTriggers } from '../../hooks/navigation';
import { useEffect, useState } from 'react';
import {
  canSwitchLLm,
} from '../../utils/shared';
import { useDispatch, useSelector } from 'react-redux';
import useNewChat from '../../hooks/chat-room/useNewChat';
import { toast } from 'react-hot-toast';
import { mentorActions } from '../../lib/redux/mentors/slice';
import useApi from '../../hooks/api/base/useApi';
import { getDisplayName } from '../../modules/llms/utils';
const Llmselectionmodalbox = props => {
  const auth = useSelector((state) => state.auth.data);
  const mentors = useSelector((state) => state.mentors);
  const editMentor = useSelector((state) => state.mentors.edit);
  const dispatch = useDispatch();
  const {handleCloseLLMSelectionModal, handleCloseLLMProviderModal} = useTriggers();
  const [searchInput, setSearchInput] = useState('');
  const handleSearchInputUpdate = (event) => {
    setSearchInput(event.target.value);
  }
  const { handleNewChatBtnClick } = useNewChat();
  const { t } = useTranslation();
  const api = useApi();
  const handleUpdateLocalMentors = (data) => {
    const updatedList = mentors.mentors?.results?.map((item) => {
      if (item.name === data.name) {
        return data;
      }
      return item;
    });

    dispatch(
      mentorActions.mentorsUpdated({ ...mentors.mentors, results: updatedList })
    );
  };
  const handleSelectLLM = async (name, provider) => {

    const llm = mentors.llms.find(item => item.model == name && item.provider == provider);
    dispatch(mentorActions.currentMentorLLMUpdated(llm));
    const mentor = mentors?.mentor;
    const mentorUniqueId = mentor.unique_id;

    const url = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/mentors/${mentorUniqueId}/settings/`;
    const payload = {
      llm_provider: llm.name,
      llm_name: llm.model,
    };

    const response = await api.put(url, payload);
    if (response?.data) {
      const updatedMentor = {
        ...mentor,
        llm_name: response?.data?.llm_name,
        llm_provider: response?.data?.llm_provider,
        settings: response?.data,
      };
      handleUpdateLocalMentors(updatedMentor);
    }
    toast.success(
      t(`{{name}} by {{provider}} has been set as the current LLM.`, {
        name: name,
        provider: getDisplayName(provider),
      })
    );

    handleNewChatBtnClick();
  }
  const getImageName = (name, provider) => {
    if (name?.includes('gpt') || name?.startsWith('o1-')) {
      return 'gptlogo.jpg';
    } else if (name?.includes('mixtral')) {
      return 'mistral.jpeg';
    } else if (provider?.includes('google') || name?.includes('gemma')) {
      return 'gemini_favicon_f069958c85030456e93de685481c559f160ea06b.png';
    } else if (name?.includes('llama')) {
      return '1709134772786.jpeg';
    } else if (name?.includes('claude-')) {
      return 'Claude-AI-KVIXtY-p-500.png';
    } else {
      return 'icon-generic_1icon-generic.png';
    }
  };
  const haveSelectedLLM = (llm) => {
    return mentors?.currentMentorLLM?.model === llm.llm_name && mentors?.chosenProvider === mentors?.currentMentorLLM?.provider
  }
  const isSearchResultEmpty = () => {
    return !!searchInput && !mentors.providers
      .find(
        (provider) =>
          provider.name == mentors.chosenProvider
      )
      .chat_models
      .some(
        llm =>
          llm
            .display_name
            .toLowerCase()
            .includes(searchInput.toLowerCase())
      )
  }
  return (
    <div className="modal llm-selection-modal">
      <div className="w-layout-vflex modal-container llm-selection">
        <div className="w-layout-hflex modal-header">
          <h3 className="modal-header-title">LLM Selection</h3>
          <div
            onClick={handleCloseLLMSelectionModal}
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            className="modal-close-wrapper llm-selection-modal-close"
          >
            <img
              src="/images/close_1close.png"
              loading="lazy"
              alt="Close LLM Selection Modal"
            />
          </div>
        </div>
        <div className="w-layout-vflex modal-body dataset-resource-modal-body">
          <div className="w-layout-hflex flex-block-485">
            <div className="text-block-225">
              Choose your preferred LLM from the available provider to tailor
              your experience.
            </div>
            <div className="w-layout-hflex flex-block-486">
              <div className="code-embed-24 w-embed">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#969696"
                >
                  <path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"></path>
                </svg>
              </div>
              <div className="form-block-25 w-form">
                <form
                  id="email-form-17"
                  name="email-form-17"
                  data-name="Email Form 17"
                  method="get"
                  data-wf-page-id="678a7aff3a55326deb175ff9"
                  data-wf-element-id="2714dc80-65e1-27a2-83fa-dd6d8c2db1da"
                  aria-label="Email Form 17"
                >
                  <input
                    className="text-field-14 w-input"
                    maxLength="256"
                    name="search-2"
                    data-name="Search 2"
                    placeholder="Search"
                    type="email"
                    id="search-2"
                    required=""
                    value={searchInput}
                    onChange={handleSearchInputUpdate}
                  />
                </form>
                <div
                  className="w-form-done"
                  tabIndex="-1"
                  role="region"
                  aria-label="Email Form 17 success"
                >
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div
                  className="w-form-fail"
                  tabIndex="-1"
                  role="region"
                  aria-label="Email Form 17 failure"
                >
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isSearchResultEmpty() && (
            <div className="w-layout-hflex flex-block-469">
              <div className="text-block-212">
                Sorry, we didn't find any LLMs that match your search!
              </div>
            </div>
          )}
          <div className="w-layout-grid llm-grid">

            {!!mentors?.chosenProvider &&
              mentors.providers
                .find((provider) => provider.name == mentors.chosenProvider)
                .chat_models.map((llm, index) => {
                  if (
                    !!searchInput &&
                    !llm.display_name
                      .toLowerCase()
                      .includes(searchInput.toLowerCase())
                  ) {
                    return;
                  }
                  const parsedLlm = mentors.llms.find(
                    (item) =>
                      item.model == llm.llm_name &&
                      item.provider == mentors.chosenProvider
                  );
                  return (
                    <div
                      style={{
                        color: canSwitchLLm(parsedLlm)
                          ? null
                          : 'lightslategray',
                        cursor: canSwitchLLm(parsedLlm)
                          ? 'pointer'
                          : 'not-allowed',
                      }}
                      key={`llm-selection-${llm.llm_name}-${index}`}
                      className="w-layout-hflex resource-card"
                      onClick={() => {
                        if (canSwitchLLm(parsedLlm) && !haveSelectedLLM(llm))
                          handleSelectLLM(llm.llm_name, mentors.chosenProvider);
                      }}
                    >
                      <img
                        className={canSwitchLLm(parsedLlm) ? "image-184" : "dimmed-image image-184"}
                        src={`/images/${getImageName(llm.llm_name, mentors.chosenProvider)}`}
                        loading="lazy"
                        sizes="100vw"
                        alt=""
                      />
                      <div>
                        {/*<strong className="bold-text-29">GPT-4o Mini</strong>*/}
                        {haveSelectedLLM(llm) ? (
                          <strong className="bold-text-29">
                            {llm.display_name}
                          </strong>
                        ) : (
                          llm.display_name
                        )}
                      </div>
                    </div>
                  );
                })}
          </div>
          <div className="w-layout-vflex data-resource-upload-box-block"></div>
        </div>
      </div>
    </div>
  );
}
export default Llmselectionmodalbox;