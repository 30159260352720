import Llmbox from '../../components/Llmbox/Llmbox';
import Loader from '../loader';
import {useTriggers} from "../../hooks/navigation";
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useLLMs from '../../hooks/llm/useLLMs';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import { useDebounce } from '../../hooks/useDebounce';
import { mentorActions } from '../../lib/redux/mentors/slice';
import NoRecordFound from '../../components/NoRecordFound/NoRecordFound';
import useApi from '../../hooks/api/base/useApi';
import {canSwitchProvider, loadLLMsRaw, getDisplayName, getImageName, handleChooseProvider} from './utils';
export default function LLMs({ showSelected }) {
  const mentors = useSelector((state) => state.mentors);
  const api = useApi();
  const editMentor = useSelector((state) => state.mentors.edit);
  const { modals } = useSelector((state) => state.navigation.data);
  const {handleOpenLLMSelectionModal} = useTriggers();
  const [page, setPage] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  const [size, setSize] = useState(8);
  const [totalPages, setTotalPage] = useState(1);
  const [searchStarted, setSearchStarted] = useState(false);
  // Move the selected LLM to the top of list
  /*const selectedLLM = mentors?.llms?.find(
    (llm) => editMentor?.mentor?.llm_name === llm.model
  );*/
  const [llms, setLLMs] = useState([]);
  const auth = useSelector((state) => state.auth.data);
  const [filteredLLMs, setFilteredLLMs] = useState([]);
  const dispatch = useDispatch();
  const { loading, error } = useLLMs();
  const [providerLoaded, setProviderLoaded] = useState(false);
  useEffect(() => {
    if (!loading && !error) {
      setProviderLoaded(true);
      return;
    }
    if (!loading && error) {
      loadLLMsRaw(auth, api, dispatch, mentorActions).then(
        () => setProviderLoaded(true)
      )
    }
  }, [loading]);

  /*if (selectedLLM) {
    llms.unshift(selectedLLM);
  }*/
  const isSearchResultEmptyProviders = () => {
    return !!searchInput && !mentors.providers
      .some(
        provider =>
          getDisplayName(provider.name)
            .toLowerCase()
            .includes(searchInput.toLowerCase())
      )
  }

  return (
    <div className="w-layout-vflex llm-tab">
      <div className="w-layout-hflex search-box-block all-whiten-in-dark-mode">
        <img
          alt=""
          className="image-49"
          loading="lazy"
          src="/images/search-1.svg"
        />
        <div className="search-box-form-block w-form">
          <form
            data-name="Email Form 4"
            data-wf-element-id="b2182b0b-09e9-b9e8-0941-94ce822aa70a"
            data-wf-page-id="650305782bc61751e5765214"
            id="email-form-4"
            method="get"
            name="email-form-4"
          >
            <input
              className="text-field-4 w-input"
              data-name="Name 5"
              id="name-5"
              maxLength={256}
              name="search"
              onChange={(event) => setSearchInput(event?.target?.value)}
              placeholder="Search Providers"
              value={searchInput}
              type="text"
            />
          </form>
        </div>
      </div>
      {providerLoaded && !!mentors?.providers && !isSearchResultEmptyProviders() ? (
          <div className="w-layout-grid grid-3">
            {
              mentors.providers.map((provider, index) => {
                if (!!searchInput && !getDisplayName(provider.name).toLowerCase().includes(searchInput.toLowerCase())) {
                  return;
                } else
                  return <div key={`llm-provider-icon-${index}`}
                              data-w-id="249fc066-2c04-4639-c8fb-7759d57a6fb5" className="w-layout-hflex resource-card"
                              onClick={() => canSwitchProvider(mentors, provider.name) ? handleChooseProvider(provider.name, dispatch, mentorActions, handleOpenLLMSelectionModal) : null}>
                    <img
                      src={`/images/${getImageName(provider.name)}`} loading="lazy" alt=""
                      className={canSwitchProvider(mentors, provider.name) ? "image-184" : "image-184 dimmed-image"} />
                    <div>{
                      mentors?.currentMentorLLM?.provider === provider.name ?
                        <strong className="bold-text-29">{getDisplayName(provider.name)}</strong>
                        :
                        getDisplayName(provider.name)
                    }</div>
                  </div>
              })

            }
          </div>
      ) : (
        <NoRecordFound />
      )}
    </div>
  );
}
