import { useSelector } from 'react-redux';
import Promptgallery from '../../components/Promptgallery/Promptgallery';
import Featuredmentorsbox from '../../components/Featuredmentorsbox/Featuredmentorsbox';
import Llmsmodalbox from '../../components/Llmsmodalbox/Llmsmodalbox';
import Llminfomodalbox from '../../components/Llminfomodalbox/Llminfomodalbox';
import Helpmodalbox from '../../components/Helpmodalbox/Helpmodalbox';
import FaqModalBox from '../../components/FaqModalBox/FaqModalBox';
import Manageaccountmodalbox from '../../components/Manageaccountmodalbox/Manageaccountmodalbox';
import Userssettingsmodalbox from '../../components/Userssettingsmodalbox/Userssettingsmodalbox';
import Mentorslistmodalbox from '../../components/Mentorslistmodalbox/Mentorslistmodalbox';
import Llmsetapimodalbox from '../../components/Llmsetapimodalbox/Llmsetapimodalbox';
import Editmentormodalbox from '../../components/Editmentormodalbox/Editmentormodalbox';
import Creatementormodalbox from '../../components/Creatementormodalbox/Creatementormodalbox';
import Addnewpromptmodalbox from '../../components/Addnewpromptmodalbox/Addnewpromptmodalbox';
import Invitenewusermodalbox from '../../components/Invitenewusermodalbox/Invitenewusermodalbox';
import Datasetresourcesmodalbox from '../../components/Datasetresourcesmodalbox/Datasetresourcesmodalbox';
import WebRTCChat from '../chat-room/bottom-area/web-rtc-chat';
import FeaturedMentorInfoModal from '../mentors/featured/FeaturedMentorInfoModal';
import { FreeTrialModal } from '../trial-banner';
import Inviteduserslistmodalbox from '../../components/Inviteduserslistmodalbox/Inviteduserslistmodalbox';
import Dataseturlresourceuploadmodalbox from '../../components/Dataseturlresourceuploadmodalbox/Dataseturlresourceuploadmodalbox';
import Promptgallerydetailmodalbox from '../../components/Promptgallerydetailmodalbox/Promptgallerydetailmodalbox';
import MentorSearchModal from '../../components/MentorSearchModal';
import Exportchatmodalbox from '../../components/Exportchatmodalbox/Exportchatmodalbox';
import Billingoverviewmodalbox from '../../components/Billingoverviewmodalbox/Billingoverviewmodalbox';
import Paymentmethodmodalbox from '../../components/Paymentmethodmodalbox/Paymentmethodmodalbox';
import Datasetlistmodalbox from '../../components/Datasetlistmodalbox/Datasetlistmodalbox';
import Billinghistorymodalbox from '../../components/Billinghistorymodalbox/Billinghistorymodalbox';
import Usagelimitmodalbox from '../../components/Usagelimitmodalbox/Usagelimitmodalbox';
import Integrationapikeymodalbox from '../../components/Integrationapikeymodalbox/Integrationapikeymodalbox';
import Createsecretkeymodalbox from '../../components/Createsecretkeymodalbox/Createsecretkeymodalbox';
import DatasetResourceTypesModal from '../mentors/edit/dataset-resource/dataset-resource-types-modal';
import Datasetyoutuberesourceuploadmodalbox from '../../components/Datasetyoutuberesourceuploadmodalbox/Datasetyoutuberesourceuploadmodalbox';
import Renamechatmodalbox from '../../components/Renamechatmodalbox/Renamechatmodalbox';
import Chathistorypinmodalbox from '../../components/Chathistorypinmodalbox/Chathistorypinmodalbox';
import Deletechatmodalbox from '../../components/Deletechatmodalbox/Deletechatmodalbox';
import DocumentPreviewModal from '../documents/preview';
import React from 'react';
import Promptdesignmodalbox from '../../components/Promptdesignmodalbox/Promptdesignmodalbox';
import Apicredentialaddmodalbox from '../../components/Apicredentialaddmodalbox/Apicredentialaddmodalbox';
import Apicredentialchangemodalbox from '../../components/Apicredentialchangemodalbox/Apicredentialchangemodalbox';
import Apicredentialdeletemodalbox from '../../components/Apicredentialdeletemodalbox/Apicredentialdeletemodalbox';
import PricingIframeBox from '../../components/PricingIframeBox/PricingIframeBox';
import Llmprovidermodalbox from '../../components/Llmprovidermodalbox/Llmprovidermodalbox';
import Llmselectionmodalbox from '../../components/Llmselectionmodalbox/Llmselectionmodalbox';

export default function Modals() {
  const { modals } = useSelector((state) => state.navigation.data);

  return (
    <>
      {modals?.['prompt-gallery-modal'] && <Promptgallery />}

      {modals?.['featured-mentors-modals'] && <Featuredmentorsbox />}

      {modals?.['llm-modal'] && <Llmsmodalbox />}

      {modals?.['llm-info-modal'] && <Llminfomodalbox />}

      <Promptdesignmodalbox />

      {modals?.['help-modal'] && <Helpmodalbox />}
      {modals?.['faq-modal'] && <FaqModalBox />}
      <Exportchatmodalbox></Exportchatmodalbox>
      {modals?.['manage-account-modal'] && <Manageaccountmodalbox />}
      <Billingoverviewmodalbox></Billingoverviewmodalbox>
      <Paymentmethodmodalbox></Paymentmethodmodalbox>

      {modals?.['users-settings-modal'] && <Userssettingsmodalbox />}

      {modals?.['mentor-list-modal'] && <Mentorslistmodalbox />}

      {modals?.['pricing-iframe-box'] && <PricingIframeBox />}

      {modals?.['edit-mentor-modal'] && <Editmentormodalbox />}

      {modals?.['llm-provider-modal'] && <Llmprovidermodalbox />}
      {modals?.['llm-selection-modal'] && <Llmselectionmodalbox />}

      {modals?.['llm-set-api-key-modal'] && <Llmsetapimodalbox />}
      {modals?.['add-resource-modal'] && <DatasetResourceTypesModal />}
      {modals?.['change-api-credential-modal'] && (
        <Apicredentialchangemodalbox />
      )}
      {modals?.['add-api-credential-modal'] && <Apicredentialaddmodalbox />}
      {modals?.['delete-api-credential-modal'] && (
        <Apicredentialdeletemodalbox />
      )}

      {modals?.['mentor-creation-modal'] && <Creatementormodalbox />}

      <Datasetlistmodalbox></Datasetlistmodalbox>
      <Billinghistorymodalbox></Billinghistorymodalbox>

      <Usagelimitmodalbox></Usagelimitmodalbox>
      <Integrationapikeymodalbox></Integrationapikeymodalbox>
      {modals?.['add-new-prompt-modal'] && <Addnewpromptmodalbox />}
      <Createsecretkeymodalbox></Createsecretkeymodalbox>
      {modals?.['users-settings-invite-modal'] && <Invitenewusermodalbox />}

      {modals?.['users-settings-invited-users-list-modal'] && (
        <Inviteduserslistmodalbox />
      )}

      {modals?.['dataset-url-resource-modal'] && (
        <Dataseturlresourceuploadmodalbox />
      )}

      <Datasetyoutuberesourceuploadmodalbox />

      {modals?.['prompt-gallery-element-view-modal'] && (
        <Promptgallerydetailmodalbox />
      )}

      <Renamechatmodalbox></Renamechatmodalbox>
      <Chathistorypinmodalbox></Chathistorypinmodalbox>
      <Deletechatmodalbox></Deletechatmodalbox>

      {modals?.['ai-audio-chat-modal'] && <WebRTCChat />}

      {modals?.['featured-mentor-info-modal'] && <FeaturedMentorInfoModal />}

      {modals?.['not-admin-banner-modal'] && <FreeTrialModal />}
      {modals?.['mentor-search-modal'] ? <MentorSearchModal /> : null}
    </>
  );
}
