import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useApi from '../api/base/useApi';

import { chatActions } from '../../lib/redux/chat/slice';

export default function useGuidedSuggestedPrompts() {
  const auth = useSelector((state) => state.auth?.data);
  const sessionId = useSelector((state) => state.chat.sessionId);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const api = useApi();
  // const mentor = useSelector((state) => state.mentors.mentor);
  // const enableGuidedPrompts = mentor?.enable_suggested_prompts;

  const loadGuidedPrompts = async () => {
    const axdToken = localStorage.getItem('axd_token');
    const loggedInTenant = localStorage.getItem('tenant');

    // If the user is not logged in or is logged into a tenant different from
    // the one of the mentor in use, don't load guided prompts for the mentor in use
    if (!axdToken || auth?.tenant?.key !== loggedInTenant || loading) {
      return;
    }

    const url = `/api/ai-prompt/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/sessions/${sessionId}/guided-prompts/`;
    setLoading(true);
    const response = await api.get(url);
    setLoading(false);
    if (response?.data) {
      const prompts = response.data?.ai_prompts;
      if (prompts?.length > 0) {
        setGuidedPrompts(prompts);
      } else {
        const retry = await api.get(url);
        if (retry?.data?.ai_prompts?.length > 0) {
          setGuidedPrompts(retry.data.ai_prompts);
        }
      }
    }
  };

  const setGuidedPrompts = (prompts) => {
    dispatch(chatActions.guidedPromptsUpdated(prompts));
  };

  const handleReloadGuidedQuestionsBtnClick = () => {
    loadGuidedPrompts();
  };

  return { handleReloadGuidedQuestionsBtnClick, loadGuidedPrompts, loading };
}
