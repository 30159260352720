import useApi from '../base/useApi';
import { useSelector } from 'react-redux';
import { userIsOnTrial } from '../../../utils/shared';

export default function useMentorDetails() {
  const auth = useSelector((state) => state.auth.data);
  const api = useApi();

  const loadMentorDetails = async (tenant, slug) => {
    const url = `/api/search/ai-search/?tenant="${tenant}"&facet=["${slug}"]`;
    const response = await api.get(url);
    if (response?.data) {
      return response.data;
    }
  };

  const loadMentorSettings = async (tenant, unique_id) => {
    if (userIsOnTrial()) {
      return;
    }

    const baseUrl = `/api/ai-mentor/orgs/${tenant}/users/${auth?.user.user_nicename}/mentors/${unique_id}`;

    const [moreSettings, settings] = await Promise.all([
      api.get(`${baseUrl}/`),
      api.get(`${baseUrl}/settings/`),
    ]);

    if (!moreSettings?.data && !settings?.data) {
      return;
    }

    return {
      ...(moreSettings?.data || {}),
      ...(settings?.data || {}),
    };
  };

  return { loadMentorDetails, loadMentorSettings };
}
