import PageLoader from '../loader/page-loader';
import { useEffect } from 'react';
import { redirectToAuth } from '../../utils/auth';
import { useParams } from 'react-router-dom';
import { api } from '@iblai/ibl-web-react-common';

export default function Login() {
  const { platform } = useParams();
  useEffect(() => {
    localStorage.clear();
    redirectToAuth(true, null, platform);
    api.ibledxauth.getTenantSsoUrl(
      platform,
      (data) => {
        localStorage.setItem('redirect-path', '/');
        if (data?.sso_login_url) {
          window.location.href = `${process.env.REACT_APP_IBL_SPA_AUTH_URL}/login?app=mentor&enforce-login=1&sso_login_url=${encodeURIComponent(data.sso_login_url)}`;
        } else {
          redirectToAuth(true, null, platform);
        }
      },
      () => {
        redirectToAuth(true, null, platform);
      }
    );
  }, [platform]);
  return <PageLoader />;
}
